import React, { useState, useEffect } from 'react'
import './styles.css'
import { Element } from "react-scroll";
import { connect } from 'react-redux'
import Modal from '../components/modal'
import { getMediaByUsername } from 'nanogram.js';
import { Helmet } from "react-helmet";
import ReactTooltip from 'react-tooltip';
function Home(props) {
    const [data, setData] = useState({})
    const [modal, setModal] = useState(false)
    const [photos, setPhotos] = useState([])
    let slideIndex = 0;
    const handleService = (data) => {
        setData(data)
        setModal(true)
    }
    const slider = () => {
        let i;
        var slides = document.getElementsByClassName("heroImagen");
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slideIndex++;
        if (slideIndex > slides.length) { slideIndex = 1 }
        slides[slideIndex - 1].style.display = "block";
        //seformData.append('nombre', file.nombre);tTimeout(showSlides, 2000); // Change image every 2 seconds
    }
    useEffect(async () => {
        document.getElementsByClassName('logo')[0].style.maxWidth="300px";
        let tooltip = document.querySelectorAll(`[data-tip][data-for="showAll"]`)[0]
        ReactTooltip.show(tooltip)
        setInterval(() => slider(), 5000);
        if(props.data.Slogan.length>25){
            document.getElementById('slogan').style.fontSize="1.2em";
        }
        document.getElementById('slogan').innerHTML = props.data.Slogan;
        document.getElementById('slogan').style.fontSize = props.data.sizeSlogan;
    }, [])

    const handleShare = async (e, url) => {
        // Evitamos el comportamiento por default del enlace
        e.preventDefault();
        const shareData = {
            title: "Elemental",
            text: '¡Hola! te comparto esta tarjeta digital',
            url: url,
            image: `https://plataforma.greencardpv.com/solicitudes/${window.subdominio}/${props.data.UrlLogo}`,
            description: 'Greencardpv'
        }
        await navigator.share(shareData)
    }

    return (
        
        <div className="app" style={{ backgroundColor: `#${props.data.ColorDegradado}` }}>
            <Helmet>
                <title>{props.data.titleHtml}</title>
                <link rel="shortcut icon" type="image/png" href={`https://plataforma.greencardpv.com/solicitudes/${window.subdominio}/${props.data.Favicon}`} sizes="64x64 32x32 24x24 16x16" />
                <link rel="apple-touch-icon" href={`https://plataforma.greencardpv.com/solicitudes/${window.subdominio}/logo192.png`} sizes="192x192"/>
                <link rel="apple-touch-icon" href={`https://plataforma.greencardpv.com/solicitudes/${window.subdominio}/logo512.png`} sizes="512x512"/>
                <meta name="description" content={window.subdominio} />
                <meta name="theme-color" content={`#${props.data.ColorDegradado}`} />
            </Helmet>
            <Element className="bodyCard" id="bodyCard">
                <Element name="Section1" style={{ backgroundColor: `#${props.data.ColorDegradado}` }}>
                    <div className="hero" id="hero">
                        <div className="degradado" style={{ background: `linear-gradient(rgba(255,255,255,0), #${props.data.ColorDegradado})` }}></div>
                        {props.data.TotalSlider.map(datos => {
                            if (props.data.TotalSlider.length == 1) {
                                return (
                                    <div className="heroImagen" key={datos} style={{
                                        backgroundImage: `url("https://plataforma.greencardpv.com/solicitudes/${window.subdominio}/${datos.url_logo}")`
                                    }}> </div>
                                )
                            } else {
                                return (
                                    <div className="heroImagen hiden" key={datos} style={{
                                        backgroundImage: `url("https://plataforma.greencardpv.com/solicitudes/${window.subdominio}/${datos.url_logo}")`
                                    }}> </div>
                                )
                            }
                        })}
                    </div>
                    <div className="ContentHero">
                        <div className="logo">
                            <img src={`https://plataforma.greencardpv.com/solicitudes/${window.subdominio}/${props.data.UrlLogo}`} style={{height:props.data.LogoHeight}} alt="" />
                            
                        </div>
                        <div className="titleHero">
                            <article id="slogan" style={{fontFamily:props.data.fuenteSlogan}}></article>
                            <div className="vistas">
                                <i className="fas fa-eye"></i><strong> {props.data.Vistas}</strong>
                            </div>
                        </div>
                        <div className="barraContact">
                            {props.data.TotalSocial.map(datos => {
                                if (datos.icono === 'fas fa-phone') {
                                    return (
                                        <a key={datos} href={`tel:${datos.link}`} className="contactIcon"><i className={`${datos.icono}`}></i></a>
                                    )
                                }else
                                if (datos.icono === 'fab fa-whatsapp') {
                                    return (
                                        <a key={datos} href={`https://wa.me/${datos.link}?text=Excelente%20día,%20vengo%20de%20tu%20*Greencardpv*`} className="contactIcon"><i className={`${datos.icono}`}></i></a>
                                    )
                                }else
                                if (datos.icono === 'far fa-envelope' || datos.icono ==='fas fa-at') {
                                    return (
                                        <a key={datos} href={`mailto:${datos.link}`} className="contactIcon"><i className={`${datos.icono}`}></i></a>
                                    )
                                }else
                                if (datos.icono === 'fas fa-info') {
                                    /*return (
                                        <a key={datos}  className="contactIcon"><i className={`${datos.icono}`}></i></a>
                                    )*/
                                } else {
                                    return (
                                        <a key={datos} href={`${datos.link}`} className="contactIcon"><i className={`${datos.icono}`}></i></a>
                                    )
                                }
                            })}
                            {Object.values(props.data.TotalService).map(serviceExtra => {
                                if(serviceExtra.tipo_servicio==1){
                                    return (
                                        <a data-tip data-for="showAll" key={serviceExtra} href={`#!`} onClick={() => handleService(serviceExtra)} className="contactIcon"><i className={`${serviceExtra.icono}`}></i></a>
                                    )
                                }
                            })}
                            <a href="" onClick={(eve) => handleShare(eve, `https://${window.subdominio}.greencardpv.com/`)} className="contactIcon"><i className="fas fa-share-alt"></i></a>
                        </div>
                        <div className="wrapperMenu">
                            <a href="https://greencardpv.com/" className="greenCard colMenu">
                                <img src="../../images/greencard.png" alt="" />
                            </a>
                        </div>
                    </div>
                </Element>
            </Element>
            <Modal modal={modal} data={data} function={setModal} />
            <ReactTooltip id="showAll" place="bottom" effect="solid">
                Ver más
            </ReactTooltip>
        </div>
    )
}

const PropsStore = state => {
    return {
        data: state.dataPlantilla.data
    }
}

export default connect(PropsStore, null)(Home)